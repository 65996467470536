import Auth from './Auth.ts';

export async function APICreatePosition(p_OfenID, p_Name, p_Kommentar, p_Durchmesser, p_OfenmantelVerschmutzung, p_OfenmantelVerformung, p_Auskleidungsmaterial, p_Auskleidungsmethode, p_ArbeitsfutterHersteller, p_ArbeitsfutterProdukt, 
  p_IsolierfutterHersteller, p_IsolierfutterProdukt, p_IsolierfutterDicke, p_MoertelArbeitsfutter, p_MoertelIsolierfutter, p_AnkerLieferant, p_AnkerTyp, p_AnkerQualitaet, p_FormatTyp, p_FormatEins, p_FormatZwei, p_PassSteinEins, p_PassSteinZwei,
  p_FormatEinsRatio, p_FormatZweiRatio, p_RingeVerband, p_KnirschMoertel, p_Haltering, p_Konus, p_Lifterzone, p_Kettenzone, p_Kammfutter, p_Opferbleche, p_Dammring, p_Spiralmauerwerk, p_Startposition, p_Laenge, p_Staerke) {

  return Auth().then((headers) => {
    const params = {
      OfenID: p_OfenID,
      Name: p_Name,
      Kommentar: p_Kommentar,
      Durchmesser: p_Durchmesser,
      OfenmantelVerschmutzung: p_OfenmantelVerschmutzung,
      OfenmantelVerformung: p_OfenmantelVerformung,
      Auskleidungsmaterial: p_Auskleidungsmaterial,
      Auskleidungsmethode: p_Auskleidungsmethode,
      ArbeitsfutterHersteller: p_ArbeitsfutterHersteller,
      ArbeitsfutterProdukt: p_ArbeitsfutterProdukt,
      IsolierfutterHersteller: p_IsolierfutterHersteller,
      IsolierfutterProdukt: p_IsolierfutterProdukt,
      IsolierfutterDicke: p_IsolierfutterDicke,
      MoertelArbeitsfutter: p_MoertelArbeitsfutter,
      MoertelIsolierfutter: p_MoertelIsolierfutter,
      AnkerLieferant: p_AnkerLieferant,
      AnkerTyp: p_AnkerTyp,
      AnkerQualitaet: p_AnkerQualitaet,
      FormatTyp: p_FormatTyp,
      FormatEins: p_FormatEins,
      FormatZwei: p_FormatZwei,
      PassSteinEins: p_PassSteinEins,
      PassSteinZwei: p_PassSteinZwei,
      FormatEinsRatio: p_FormatEinsRatio,
      FormatZweiRatio: p_FormatZweiRatio,
      RingeVerband: p_RingeVerband,
      KnirschMoertel: p_KnirschMoertel,
      Haltering: p_Haltering,
      Konus: p_Konus,
      Lifterzone: p_Lifterzone,
      Kettenzone: p_Kettenzone,
      Kammfutter: p_Kammfutter,
      Opferbleche: p_Opferbleche,
      Dammring: p_Dammring,
      Spiralmauerwerk: p_Spiralmauerwerk,
      Startposition: p_Startposition,
      Laenge: p_Laenge,
      Staerke: p_Staerke
    };
    const url = window.config.BACKENDURL + "/api/v1/Position";
    return fetch(url, {
      method: "POST",
      //@ts-ignore
      headers: headers,
      body: JSON.stringify(params)
    }).then((result) => {
      return result.json();
    });
  });
}