import { PublicClientApplication,InteractionRequiredAuthError } from "@azure/msal-browser";
//import {configuration} from '../config';
//@ts-ignore
const configuration = window.config.AUTHCONFIGURATION;
const msalInstance = new PublicClientApplication(configuration);
export default function Auth()
{

  var request = {
    //@ts-ignore
    scopes: [window.config.SCOPE],
    //@ts-ignore
    account : msalInstance.getAllAccounts()[0]
  };
  //@ts-ignore


    return  msalInstance.acquireTokenSilent(request).then(tokenResponse => {
    var headers = new Headers();
    var bearer = "Bearer " + tokenResponse.accessToken;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    return headers;
    }).catch(async (error) => {
        console.log(error);
        //@ts-ignore
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            //@ts-ignore
            return msalInstance.acquireTokenPopup(request);
        }
      }).catch(error => {
        //@ts-ignore
        console.log(error);
      });;

}