import Auth from './Auth.ts';

export async function APIDeleteOven(ID) {

    return Auth().then((headers) => {
        const url = window.config.BACKENDURL + "/api/v1/Oven/" + ID;
        return fetch(url, {
          method: "DELETE",
          //@ts-ignore
          headers: headers
        }).then((result) => {
          return result;
        });
      });
}