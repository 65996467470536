import useLiningChartsState from "../state/liningcharts";

export default function Positionsinfo()
{
    const DisplayedPosition = useLiningChartsState(state => state.DisplayedPosition);
    const positionMode = useLiningChartsState(state => state.positionMode);
    const setDisplayedPosition = useLiningChartsState(state => state.setDisplayedPosition);


function editDisplayProp(propname,value)
{
    let newdisplayedprop = {...DisplayedPosition};
    newdisplayedprop[propname] = value;
    setDisplayedPosition(newdisplayedprop);
}

function editDisplayPropHistorieLaenge(value)
{
    let newdisplayedprop = {...DisplayedPosition};
    newdisplayedprop.historie[newdisplayedprop.historie.length-1].laenge = value;
    setDisplayedPosition(newdisplayedprop);
}
function editDisplayPropHistorieStartposition(value)
{
    let newdisplayedprop = {...DisplayedPosition};
    newdisplayedprop.historie[newdisplayedprop.historie.length-1].startposition = value;
    setDisplayedPosition(newdisplayedprop);
}
function editDisplayPropHistorieStaerke(value)
{
    let newdisplayedprop = {...DisplayedPosition};
    newdisplayedprop.historie[newdisplayedprop.historie.length-1].staerke = value;
    setDisplayedPosition(newdisplayedprop);
}




if(DisplayedPosition ===null)
return<div></div>;

return(
    <div>

    <label>Name</label>
      <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.name} onChange={(e)=>{editDisplayProp("name",e.target.value)}} />

      <label>Kommentar</label>
      <textarea disabled={positionMode != "new"} value={DisplayedPosition.kommentar} onChange={(e)=>{editDisplayProp("kommentar",e.target.value)}} name="Text1" cols="40" rows="5"></textarea>

      <label>Durchmesser(mm)</label>
      <input disabled={positionMode != "new"} value={DisplayedPosition.durchmesser} onChange={(e)=>{editDisplayProp("durchmesser",e.target.value)}} />

      <fieldset>
        <legend>Allgemein</legend>
        <label>Startposition(m)</label>
        <input disabled={positionMode == "none"} maxLength="100" value={DisplayedPosition.historie[DisplayedPosition.historie.length-1].startposition} onChange={(e) => editDisplayPropHistorieStartposition((e.target.value))} />
        <label>Länge(m)</label>
        <input disabled={positionMode == "none"} maxLength="100" value={DisplayedPosition.historie[DisplayedPosition.historie.length-1].laenge} onChange={(e) => editDisplayPropHistorieLaenge((e.target.value))} />
        <label>Stärke(mm)</label>
        <input disabled={positionMode == "none"} maxLength="100" value={DisplayedPosition.historie[DisplayedPosition.historie.length-1].staerke} onChange={(e) => editDisplayPropHistorieStaerke((e.target.value))} />
      </fieldset>

      <fieldset>
        <legend>Ofenmantelverschmutzung</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Ofenmantelverschmutzung_none" name="Ofenmantelverschmutzung" value="none" checked={DisplayedPosition.ofenmantelVerschmutzung == "none"} onChange={(e)=>{editDisplayProp("ofenmantelVerschmutzung","none")}} />
          <label htmlFor="Ofenmantelverschmutzung_none">Nicht verschmutzt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Ofenmantelverschmutzung_light" name="Ofenmantelverschmutzung" value="light" checked={DisplayedPosition.ofenmantelVerschmutzung == "light"} onChange={(e)=>{editDisplayProp("ofenmantelVerschmutzung","light")}} />
          <label htmlFor="Ofenmantelverschmutzung_light">Leicht verschmutzt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Ofenmantelverschmutzung_strong" name="Ofenmantelverschmutzung" value="strong" checked={DisplayedPosition.ofenmantelVerschmutzung == "strong"} onChange={(e)=>{editDisplayProp("ofenmantelVerschmutzung","strong")}} />
          <label htmlFor="Ofenmantelverschmutzung_strong">Stark verschmutzt</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Ofenmantelverformung</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="OfenmantelVerformung_none" name="OfenmantelVerformung" value="none" checked={DisplayedPosition.ofenmantelVerformung == "none"} onChange={(e)=>{editDisplayProp("ofenmantelVerformung","none")}} />
          <label htmlFor="OfenmantelVerformung_none">Nicht verformt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="OfenmantelVerformung_light" name="OfenmantelVerformung" value="light" checked={DisplayedPosition.ofenmantelVerformung == "light"} onChange={(e)=>{editDisplayProp("ofenmantelVerformung","light")}} />
          <label htmlFor="OfenmantelVerformung_light">Leicht verformt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="OfenmantelVerformung_strong" name="OfenmantelVerformung" value="strong" checked={DisplayedPosition.ofenmantelVerformung == "strong"} onChange={(e)=>{editDisplayProp("ofenmantelVerformung","strong")}} />
          <label htmlFor="OfenmantelVerformung_strong">Stark verformt</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Auskleidungsmaterial</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Auskleidungsmaterial_stein" name="Auskleidungsmaterial" value="stein" checked={DisplayedPosition.auskleidungsmaterial == "stein"} onChange={(e)=>{editDisplayProp("auskleidungsmaterial","stein");editDisplayProp("auskleidungsmethode","einschicht")}} />
          <label htmlFor="Auskleidungsmaterial_stein">Steine</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Auskleidungsmaterial_masse" name="Auskleidungsmaterial" value="masse" checked={DisplayedPosition.auskleidungsmaterial == "masse"} onChange={(e)=>{editDisplayProp("auskleidungsmaterial","masse");editDisplayProp("auskleidungsmethode","giessen_vibrieren")}} />
          <label htmlFor="Auskleidungsmaterial_masse">Masse</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Auskleidungsmethode</legend>
        <div>
          <input disabled={positionMode != "new"} hidden={DisplayedPosition.auskleidungsmaterial == "masse"} type="radio" id="Auskleidungsmethode_einschicht" name="Auskleidungsmethode" value="einschicht" onChange={(e)=>{editDisplayProp("auskleidungsmethode","einschicht")}} checked={DisplayedPosition.auskleidungsmethode == "einschicht"} />
          <label hidden={DisplayedPosition.auskleidungsmaterial == "masse"} htmlFor="Auskleidungsmethode_einschicht">Einschichtfutter</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={DisplayedPosition.auskleidungsmaterial == "masse"} type="radio" id="Auskleidungsmethode_zweischicht" name="Auskleidungsmethode" value="zweischicht" onChange={(e)=>{editDisplayProp("auskleidungsmethode","zweischicht")}} checked={DisplayedPosition.auskleidungsmethode == "zweischicht"}  />
          <label hidden={DisplayedPosition.auskleidungsmaterial == "masse"} htmlFor="Auskleidungsmethode_zweischicht">Zweischichtfutter</label>
        </div>
                <div>
          <input disabled={positionMode != "new"} hidden={DisplayedPosition.auskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_giessen_vibrieren" name="Auskleidungsmethode" value="giessen_vibrieren" onChange={(e)=>{editDisplayProp("auskleidungsmethode","giessen_vibrieren")}} checked={DisplayedPosition.auskleidungsmethode == "giessen_vibrieren"} />
          <label hidden={DisplayedPosition.auskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_giessen_vibrieren">Gießen/Vibrieren</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={DisplayedPosition.auskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_trockenspritzen" name="Auskleidungsmethode" value="trockenspritzen" onChange={(e)=>{editDisplayProp("auskleidungsmethode","trockenspritzen")}} checked={DisplayedPosition.auskleidungsmethode == "trockenspritzen"} />
          <label hidden={DisplayedPosition.auskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_trockenspritzen">Trockenspritzen</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={DisplayedPosition.auskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_nassspritzen" name="Auskleidungsmethode" value="nassspritzen" onChange={(e)=>{editDisplayProp("auskleidungsmethode","nassspritzen")}} checked={DisplayedPosition.auskleidungsmethode == "nassspritzen"}  />
          <label hidden={DisplayedPosition.auskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_nassspritzen">Nassspritzen</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={DisplayedPosition.auskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_blockline" name="Auskleidungsmethode" value="blockline" onChange={(e)=>{editDisplayProp("auskleidungsmethode","blockline")}} checked={DisplayedPosition.auskleidungsmethode == "blockline"}  />
          <label hidden={DisplayedPosition.auskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_blockline">Refra-Blockline</label>
        </div>
      </fieldset>

      <fieldset hidden={DisplayedPosition.auskleidungsmaterial == "masse"} >
        <legend>Materialinformationen</legend>
        <label>Arbeitsfutter Hersteller</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.arbeitsfutterHersteller} onChange={(e)=>{editDisplayProp("arbeitsfutterHersteller",e.target.value)}}  />
        <label>Arbeitsfutter Produkt</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.arbeitsfutterProdukt} onChange={(e)=>{editDisplayProp("arbeitsfutterProdukt",e.target.value)}}  />
        <label hidden={DisplayedPosition.auskleidungsmethode == "einschicht"}>Isolierfutter Hersteller</label>
        <input hidden={DisplayedPosition.auskleidungsmethode == "einschicht"} disabled={positionMode != "new"} onChange={(e)=>{editDisplayProp("isolierfutterHersteller",e.target.value)}} maxLength="100" value={DisplayedPosition.isolierfutterHersteller} />
        <label hidden={DisplayedPosition.auskleidungsmethode == "einschicht"}>Isolierfutter Produkt</label>
        <input hidden={DisplayedPosition.auskleidungsmethode == "einschicht"} disabled={positionMode != "new"} onChange={(e)=>{editDisplayProp("isolierfutterProdukt",e.target.value)}} maxLength="100" value={DisplayedPosition.isolierfutterProdukt}  />
        <label hidden={DisplayedPosition.auskleidungsmethode == "einschicht"}>Isolierfutter Dicke(mm)</label>
        <input hidden={DisplayedPosition.auskleidungsmethode == "einschicht"} disabled={positionMode != "new"} onChange={(e)=>{editDisplayProp("isolierfutterDicke",e.target.value)}} value={DisplayedPosition.isolierfutterDicke}  />
        <label hidden={DisplayedPosition.knirschMoertel == "knirsch"} >Mörtel Arbeitsfutter</label>
        <input hidden={DisplayedPosition.knirschMoertel == "knirsch"} disabled={positionMode != "new"} onChange={(e)=>{editDisplayProp("moertelArbeitsfutter",e.target.value)}} maxLength="100" value={DisplayedPosition.moertelArbeitsfutter} />
        <label hidden={DisplayedPosition.auskleidungsmethode == "einschicht" || DisplayedPosition.knirschMoertel == "knirsch"}>Mörtel Isolierfutter</label>
        <input hidden={DisplayedPosition.auskleidungsmethode == "einschicht" || DisplayedPosition.knirschMoertel == "knirsch"} onChange={(e)=>{editDisplayProp("moertelIsolierfutter",e.target.value)}} disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.moertelIsolierfutter} />
      </fieldset>

      <fieldset hidden={DisplayedPosition.auskleidungsmaterial == "stein"} >
        <legend>Materialinformationen</legend>
        <label>Arbeitsfutter Hersteller</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.arbeitsfutterHersteller} onChange={(e)=>{editDisplayProp("arbeitsfutterHersteller",e.target.value)}} />
        <label>Arbeitsfutter Produkt</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.arbeitsfutterProdukt} onChange={(e)=>{editDisplayProp("arbeitsfutterProdukt",e.target.value)}} />
        <label>Ankerlieferant</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.ankerLieferant} onChange={(e)=>{editDisplayProp("ankerLieferant",e.target.value)}} />
        <label>Ankertyp</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.ankerTyp} onChange={(e)=>{editDisplayProp("ankerTyp",e.target.value)}} />
        <label>Ankerqualität</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.ankerQualitaet} onChange={(e)=>{editDisplayProp("ankerQualitaet",e.target.value)}} />
      </fieldset>

      <fieldset hidden={DisplayedPosition.auskleidungsmaterial == "masse"} >
        <legend>Formattyp</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Formattyp_vdz" name="Formattyp" value="vdz" checked={DisplayedPosition.formatTyp == "vdz"} onChange={(e)=>{editDisplayProp("formatTyp","vdz")}}/>
          <label htmlFor="Formattyp_vdz">VDZ Formate</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Formattyp_iso" name="Formattyp" value="iso" checked={DisplayedPosition.formatTyp == "iso"} onChange={(e)=>{editDisplayProp("formatTyp","iso")}} />
          <label htmlFor="Formattyp_iso">ISO Formate</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Formattyp_custom" name="Formattyp" value="custom" checked={DisplayedPosition.formatTyp == "custom"} onChange={(e)=>{editDisplayProp("formatTyp","custom")}} />
          <label htmlFor="Formattyp_custom">Sonderformate</label>
        </div>
      </fieldset>

      <fieldset hidden={DisplayedPosition.auskleidungsmaterial == "masse"} >
        <legend>Formate</legend>
        <label>Format 1</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.formatEins}  onChange={(e)=>{editDisplayProp("formatEins",e.target.value)}}/>
        <label>Format 2</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.formatZwei}  onChange={(e)=>{editDisplayProp("formatZwei",e.target.value)}}/>
        <label>Passstein 1</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.passSteinEins}  onChange={(e)=>{editDisplayProp("passSteinEins",e.target.value)}}/>
        <label>Passstein 2</label>
        <input disabled={positionMode != "new"} maxLength="100" value={DisplayedPosition.passSteinZwei}  onChange={(e)=>{editDisplayProp("passSteinZwei",e.target.value)}} />
        <label>Verhältnis Format 1</label>
        <input disabled={positionMode != "new"} value={DisplayedPosition.formatEinsRatio} onChange={(e)=>{editDisplayProp("formatEinsRatio",e.target.value)}}/>
        <label>Verhältnis Format 2</label>
        <input disabled={positionMode != "new"} value={DisplayedPosition.formatZweiRatio} onChange={(e)=>{editDisplayProp("formatZweiRatio",e.target.value)}}/>
      </fieldset>

      <fieldset hidden={DisplayedPosition.auskleidungsmaterial == "masse"}>
        <legend>Vermauerungsmethode</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Vermauerungsmethode_ringe" name="Vermauerungsmethode" value="ringe" checked={DisplayedPosition.ringeVerband == "ringe"} onChange={(e)=>{editDisplayProp("ringeVerband","ringe")}} />
          <label htmlFor="OfenmantelVerformung_none">Ringe</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Vermauerungsmethode_verband" name="Vermauerungsmethode" value="verband" checked={DisplayedPosition.ringeVerband == "verband"} onChange={(e)=>{editDisplayProp("ringeVerband","verband")}} />
          <label htmlFor="OfenmantelVerformung_light">Verband</label>
        </div>
      </fieldset>

      <fieldset hidden={DisplayedPosition.auskleidungsmaterial == "masse"}>
        <legend>Mörtel</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Moertel_knirsch" name="Moertel" value="knirsch" checked={DisplayedPosition.knirschMoertel == "knirsch"} onChange={(e)=>{editDisplayProp("knirschMoertel","knirsch")}} />
          <label htmlFor="OfenmantelVerformung_none">knirsch</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Moertel_moertel" name="Moertel" value="moertel" checked={DisplayedPosition.knirschMoertel == "moertel"} onChange={(e)=>{editDisplayProp("knirschMoertel","moertel")}} />
          <label htmlFor="OfenmantelVerformung_light">mit Mörtel</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Anmerkungen</legend>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.haltering} type="checkbox" id="PositionHaltering" onChange={(e)=>{editDisplayProp("haltering",e.target.checked)}} />
        <label htmlFor="PositionHaltering">Haltering</label>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.konus} type="checkbox" id="PositionKonus" onChange={(e)=>{editDisplayProp("konus",e.target.checked)}}  />
        <label htmlFor="PositionKonus">Konus</label>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.lifterzone} type="checkbox" id="PositionLifterzone" onChange={(e)=>{editDisplayProp("lifterzone",e.target.checked)}}  />
        <label htmlFor="PositionLifterzone">Lifterzone</label>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.kettenzone} type="checkbox" id="PositionKettenzone" onChange={(e)=>{editDisplayProp("kettenzone",e.target.checked)}} />
        <label htmlFor="PositionKettenzone">Kettenzone</label>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.kammfutter} type="checkbox" id="PositionKammfutter" onChange={(e)=>{editDisplayProp("kammfutter",e.target.checked)}} />
        <label htmlFor="PositionKammfutter">Kammfutter</label>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.opferbleche} type="checkbox" id="PositionOpferbleche" onChange={(e)=>{editDisplayProp("opferbleche",e.target.checked)}} />
        <label htmlFor="PositionOpferbleche">Opferbleche</label>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.dammring} type="checkbox" id="PositionDammring" onChange={(e)=>{editDisplayProp("dammring",e.target.checked)}}  />
        <label htmlFor="PositionDammring">Dammring</label>
        <input disabled={positionMode != "new"} checked={DisplayedPosition.spiralmauerwerk} type="checkbox" id="PositionSpiralmauerwerk"onChange={(e)=>{editDisplayProp("spiralmauerwerk",e.target.checked)}}  />
        <label htmlFor="PositionSpiralmauerwerk">Spiralmauerwerk</label>
      </fieldset>
      </div>
      );
}