import Auth from './Auth.ts';

export async function APILoadPositionDetails(ofenID, time=new Date()) {

    return Auth().then((headers) => {
        const params = {
            OfenID: ofenID,
            SelectedTime: time
        };
        const url = window.config.BACKENDURL + "/api/v1/GetPositionDetail";
        return fetch(url, {
          method: "POST",
          //@ts-ignore
          headers: headers,
          body: JSON.stringify(params)
        }).then((result) => {
            return result.json();
        });
      });
}