import Auth from './Auth.ts';
export async function APIGetOvens() {
  try {
    return Auth().then((headers) => {
      const url = window.config.BACKENDURL + "/api/v1/Oven";
      return fetch(url, {
        method: "GET",
        //@ts-ignore
        headers: headers
      }).then((result) => {
        return result.json();
      });
    });
  }
  catch {
    return undefined;
  }
}


