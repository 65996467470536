import logo from '../logo.svg';
import React from "react";
import { useNavigate } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";

import './History.css'

import { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react";
import { APILoadPositionDetails } from '../api/APILoadPositionDetails';
import LiningChart from '../components/lining_chart';


import useLiningChartsState from '../state/liningcharts';

function History() {
    const history = useNavigate();

    const Oefen = useLiningChartsState(state => state.Oefen);
    const selectedOfen = useLiningChartsState(state => state.selectedOfen);

    const OfenStateOne = useLiningChartsState(state => state.OfenStateOne);
    const setOfenStateOne = useLiningChartsState(state => state.setOfenStateOne);
    const OfenStateTwo = useLiningChartsState(state => state.OfenStateTwo);
    const setOfenStateTwo = useLiningChartsState(state => state.setOfenStateTwo);

    useEffect(() => {
        if (!Oefen || Oefen.length == 0) {
            history("/")
        }
    }, []);

    function BackToOvens() {
        history("/")
    }


    async function setOfenOne(date) {
        let datum1 = new Date(date);
        let temp1 = await APILoadPositionDetails(Oefen[selectedOfen].id, datum1);
        setOfenStateOne(temp1);
    }
    async function setOfenTwo(date) {
        let datum2 = new Date(date);
        let temp2 = await APILoadPositionDetails(Oefen[selectedOfen].id, datum2);
        setOfenStateTwo(temp2);
    }

    //console.log(OfenStateOne)

    console.log(OfenStateTwo);



    return (
        <div className='wrapper'>




            <div className='main'>
                <h2>Positionshistorie</h2>
                <div className='nachLinks'>
                    <button onClick={BackToOvens}>Zurück Zu Öfen</button>
                </div>
                <div>
                    <h3>Zustand 1</h3>
                    <input type="date" id="start" name="trip-start" onChange={(e) => { setOfenOne(e.target.value) }} />
                    <LiningChart ovendata={OfenStateOne} ovenlength={Oefen[selectedOfen] != undefined ? Oefen[selectedOfen].laenge : 1} screenwidth={window.innerWidth * 0.8} />
                </div>
                <div>
                    <h3>Zustand 2</h3>
                    <input type="date" id="start" name="trip-start" onChange={(e) => { setOfenTwo(e.target.value) }} />
                    <LiningChart ovendata={OfenStateTwo} ovenlength={Oefen[selectedOfen] != undefined ? Oefen[selectedOfen].laenge : 1} screenwidth={window.innerWidth * 0.8} />
                </div>
            </div>
        </div>
    );
}

export default History;