import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { InteractionType } from "@azure/msal-browser";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";

const authRequest = {
  scopes: ["openid", "profile"]
};
function ErrorComponent({error}) {
  return <p>An Error Occurred during Login.</p>;
}
function LoadingComponent() {
  return <p>Loading...</p>
}
const pca = new PublicClientApplication(window.config.AUTHCONFIGURATION);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}>
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
