import logo from '../logo.svg';
import React from "react";
import { useNavigate } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";
import './Ovens.css'

import { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react";

import { APIChangeOven } from '../api/APIChangeOven';
import { APIDeleteOven } from '../api/APIDeleteOven';


import useLiningChartsState from '../state/liningcharts';

function Ovens() {
  const history = useNavigate();

  const Oefen = useLiningChartsState(state => state.Oefen);
  const setOefen = useLiningChartsState(state => state.setOefen)

  const selectedOfen = useLiningChartsState(state => state.selectedOfen);
  const setSelectedOfen = useLiningChartsState(state => state.setSelectedOfen)

  const OfenName = useLiningChartsState(state => state.OfenName);
  const setOfenName = useLiningChartsState(state => state.setOfenName)
  const OfenLaenge = useLiningChartsState(state => state.OfenLaenge);
  const setOfenLaenge = useLiningChartsState(state => state.setOfenLaenge)
  const OfenKommentar = useLiningChartsState(state => state.OfenKommentar);
  const setOfenKommentar = useLiningChartsState(state => state.setOfenKommentar)

  const OfenCommand = useLiningChartsState(state => state.OfenCommand);
  const setOfenCommand = useLiningChartsState(state => state.setOfenCommand)
  const OfenResponse = useLiningChartsState(state => state.OfenResponse);
  const setOfenResponse = useLiningChartsState(state => state.setOfenResponse)
  const OfenDialogue = useLiningChartsState(state => state.OfenDialogue);
  const setOfenDialogue = useLiningChartsState(state => state.setOfenDialogue)
  const OfenLoading = useLiningChartsState(state => state.OfenLoading);
  const setOfenLoading = useLiningChartsState(state => state.setOfenLoading)
  const OfenNoConnection = useLiningChartsState(state => state.OfenNoConnection);
  const setOfenNoConnection = useLiningChartsState(state => state.setOfenNoConnection)
  const NewOvenCreation = useLiningChartsState(state => state.NewOvenCreation);
  const setNewOvenCreation = useLiningChartsState(state => state.setNewOvenCreation);
  const loadOvens = useLiningChartsState(state => state.loadOvens);


  useEffect(() => {
    ClearInputs();
    UpdateOvens();
  }, []);

  function UpdateOvens() {
    ResetOvenSelection();
    loadOvens();
    /*
     setOefen(null);
     setOfenLoading(true);
     
     APIGetOvens().then(data => {
       if (data && data.length > 0) {
         setOefen(data);
       }
       else {
         setOefen(null);
       }
       if (data == undefined) {
         setOfenNoConnection(true);
       }
       setOfenLoading(false);
     });
     */
  }

  useEffect(() => {
    if (!selectedOfen) {
      ClearInputs();
    }
    else {
      setOfenName(Oefen[selectedOfen].name);
      setOfenLaenge(Oefen[selectedOfen].laenge);
      setOfenKommentar(Oefen[selectedOfen].kommentar);
    }
  }, [selectedOfen]);


  useEffect(() => {
    if (OfenResponse == null) {
      return;
    }
    if (OfenResponse == false) {
      setOfenCommand("");
      setOfenResponse(null);
      return;
    }
    switch (OfenCommand) {
      case "del":
        setOfenLoading(true)

        APIDeleteOven(Oefen[selectedOfen].id).then(() => {
          let array = Oefen;
          array.splice(selectedOfen, 1);
          setOefen(array);
          setOfenLoading(false);
          setOfenCommand("");
          setOfenResponse(null);
        });

        break;
      case "save":
        setOfenLoading(true)
        let id;
        let length = 0;
        if (selectedOfen == null) {
          id = "0";
        }
        else {
          id = Oefen[selectedOfen].id;
        }
        if (!isNaN(OfenLaenge)) {
          length = parseFloat(OfenLaenge);
        }
        else {
          console.log("LÄNGE IST KEIN FLOAT! SPEICHERN ABGEBROCHEN!")
          setOfenLoading(false);
          return;
        }
        APIChangeOven(id, OfenName, OfenKommentar, length).then((result) => {

          if (selectedOfen == null) {
            let new_oven = { id: result.id, name: OfenName, kommentar: OfenKommentar, laenge: length }
            let temp_list;
            if (Oefen != null) {
              temp_list = Oefen;
              temp_list.push(new_oven);
            }
            else {
              temp_list = [new_oven];
            }
            setOefen(temp_list);
          }
          else {
            Oefen[selectedOfen].name = OfenName;
            Oefen[selectedOfen].kommentar = OfenKommentar;
            Oefen[selectedOfen].laenge = length;
          }
          setOfenLoading(false);
          ClearInputs();
          setOfenCommand("");
          setOfenResponse(null);
        });

        break;
      default:
        console.log("default");
    }
  }, [OfenResponse]);

  function ClearInputs() {
    setNewOvenCreation(false);
    setOfenName("");
    setOfenLaenge("");
    setOfenKommentar("");
  }


  function DeleteOven() {
    setOfenCommand("del");
    setOfenDialogue(true);

  }
  function SaveOven() {
    setOfenCommand("save");
    setOfenDialogue(true);
  }
  function newOven() {
    ResetOvenSelection();
    setNewOvenCreation(true);
  }
  function ToPositions() {
    if (selectedOfen != null) {
      history("/oven/" + Oefen[selectedOfen].id + "/positions")
    }
  }
  function ToHistory() {
    if (selectedOfen != null) {
      history("/history")
    }
  }

  function OvenSelectionChanged() {
    setNewOvenCreation(false);
    let element = document.getElementById("mySelect");
    if (Oefen != null) {
      if (Oefen.length > 0 && element.value) {
        setSelectedOfen(element.value);
      }
    }
  }
  function ResetOvenSelection() {
    let element = document.getElementById("mySelect");
    if (element) {
      element.value = null;
      setSelectedOfen(null);
    }
  }

  function DialogueYes() {
    setOfenDialogue(false);
    setOfenResponse(true);
  }
  function DialogueNo() {
    setOfenDialogue(false);
    setOfenResponse(false);
  }

  const OefenDisplay = (Oefen != null) ? Oefen.map((ofen, i) => {
    return <option key={ofen.id} value={i}>{ofen.name + " - " + ofen.laenge + "m"}</option>
  }) : [];

  const LoadingElement = (OfenLoading == true) ?
    <div className='dialogue'>
      <p>Loading...</p>
    </div>
    : [];
  const DialogueElement = (OfenDialogue == true) ?
    <div className='dialogue'>
      <p>Sind sie sich sicher?</p>
      <table>
        <tbody>
          <tr>
            <td>
              <button onClick={DialogueYes}>Ja</button>
            </td>
            <td>
              <button onClick={DialogueNo}>Nein</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    : [];

  if (OfenNoConnection == true) {
    <div>
      No Server Connection. Reload Page at a later point.
    </div>
  }



  return (
    <div className='wrapper'>
      <div className='main'>
        <h2>
          Refra Lining Charts

          {LoadingElement}
          {DialogueElement}
        </h2>
      </div>


      <div className='main'>
      <div className='nachRechts'>
        <button onClick={DeleteOven} disabled={selectedOfen == null}>Ofen Löschen</button>
      </div>
        <div>

          <div>

            <table>
              <tbody>
                <tr>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <label>Name</label>
                          </td>
                          <td>
                            <input value={OfenName} className="eingabefeld" onChange={(e) => setOfenName((e.target.value))} disabled={!NewOvenCreation && !Oefen[selectedOfen]} /> <span> &nbsp;&nbsp;</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Laenge</label>
                          </td>
                          <td>
                            <input value={OfenLaenge} className="eingabefeld" onChange={(e) => setOfenLaenge((e.target.value))} disabled={!NewOvenCreation && !Oefen[selectedOfen]} />m
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>Kommentar</label>
                          </td>
                          <td>
                            <textarea value={OfenKommentar} onChange={(e) => setOfenKommentar((e.target.value))} disabled={!NewOvenCreation && !Oefen[selectedOfen]} name="Text1" cols="40" rows="5"></textarea><span> &nbsp;&nbsp;</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                  <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </td>
                  <td>

                    <select id="mySelect" className="eingabefeld" size="10" onChange={() => OvenSelectionChanged()}>
                      {OefenDisplay}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>






          </div>
        </div>
      </div>
      <div className="twoButtons">
        <div>
          <button onClick={newOven} disabled={NewOvenCreation}>Neuer Ofen</button>
          <button onClick={SaveOven} disabled={!(selectedOfen != null || NewOvenCreation == true)}>Änderungen Speichern</button>
        </div>
        <div>
          <button onClick={ToPositions} disabled={selectedOfen == null}>Positionen</button>
          <button onClick={ToHistory} disabled={selectedOfen == null}>Historie</button>
        </div>
      </div>
    </div>
  );
}

export default Ovens;