import { useEffect, useState } from "react"


export default function LiningChart({ ovendata, ovenlength, screenwidth }) {

    const can_id = (Math.floor(Math.random() * 1000000)).toString();

    const colors = [
        ["#990000", "#FF0000", "#CC3333", "#FF6666", "#FF9999"],
        ["#996633", "#CC9900", "#FFCC00", "#FFFF00", "#FFFF99"],
        ["#006600", "#009900", "#00CC00", "#66FF66", "#99FF99"],
        ["#003333", "#006666", "#009999", "#66CCCC", "#66FFCC"],
        ["#0033FF", "#0099FF", "#00CCFF", "#00FFFF", "#99FFFF"],
        ["#000099", "#0000FF", "#3366FF", "#3399FF", "#66CCFF"],
        ["#330066", "#660099", "#9900CC", "#9966FF", "#9999FF"],
        ["#990066", "#CC0099", "#FF0099", "#FF00FF", "#FF99FF"]
    ];

    useEffect(() => {
        if (ovendata) {

            if (screenwidth < 200) {
                screenwidth = 200;
            }
            const canvas = document.getElementById(can_id);

            var color_x = -1;
            var color_y = 0;

            var color_info = [];

            function nextColor() {
                color_x++;
                if (color_x > 7) {
                    color_x = 0;
                    color_y++;
                    if (color_y > 4) {
                        color_y = 0;
                    }
                }
                return colors[color_x][color_y];
            }

            function NameToColor(name) {
                let name_lc = name.toLowerCase();
                let ret = null;

                //wenn keine sorte angegeben
                if (name == "") {
                    color_info.forEach(element => {
                        if (element.name.toLowerCase() == "keine angabe") {
                            ret = element.color;
                        }
                    });
                    if (!ret) {
                        let grey_obj = { name: "Keine Angabe", color: "#505050" };
                        color_info.push(grey_obj);
                        ret = "#505050";
                    }
                }
                if (!ret) {
                    color_info.forEach(element => {
                        if (element.name.toLowerCase() == name_lc) {
                            ret = element.color;
                        }
                    });
                }
                if (!ret) {
                    let new_color = nextColor();
                    let info_obj = { name: name, color: new_color };
                    color_info.push(info_obj);
                    ret = new_color;
                }

                return ret;
            }

            var OFENGRAFIK_BREITE = screenwidth - 50;

            function getXlevel(meter) {
                let val = OFENGRAFIK_BREITE / ovenlength;
                val = 25 + val * meter;

                return Math.floor(val);
            }

            function getWidth(lenght) {

                let val = OFENGRAFIK_BREITE / ovenlength;
                val = val * lenght;
                return Math.ceil(val);
            }

            const ctx = canvas.getContext('2d');

            ovendata.forEach(element => {
                //STEIN
                if (element.auskleidungsmaterial == "stein") {
                    //einschicht

                    if (element.auskleidungsmethode == "einschicht") {
                        NameToColor(element.arbeitsfutterProdukt);
                    }
                    //zweischicht
                    else {
                        NameToColor(element.isolierfutterProdukt);
                        NameToColor(element.arbeitsfutterProdukt);
                    }
                }
                //MASSE
                else if (element.auskleidungsmaterial == "masse") {
                    NameToColor(element.arbeitsfutterProdukt);
                }
            });



            ctx.canvas.width = screenwidth;
            ctx.canvas.height = 285 + ((color_info.length) * 25);

            ctx.strokeStyle = "#000000";
            ctx.lineWidth = 2;




            //außenform ofen
            ctx.beginPath();
            ctx.moveTo(25, 10);
            ctx.lineTo(screenwidth - 25, 10);
            ctx.lineTo(screenwidth - 25, 230);
            ctx.lineTo(25, 230);
            ctx.lineTo(25, 10);
            ctx.stroke();

            //skala linie horizontal
            ctx.beginPath();
            ctx.moveTo(25, 245);
            ctx.lineTo(screenwidth - 25, 245);
            ctx.stroke();

            //0m linie
            ctx.beginPath();
            ctx.moveTo(25, 240);
            ctx.lineTo(25, 250);
            ctx.stroke();

            //mittel-linie
            ctx.beginPath();
            ctx.moveTo((screenwidth - 50) / 2 + 25, 240);
            ctx.lineTo((screenwidth - 50) / 2 + 25, 250);
            ctx.stroke();

            //end-linie
            ctx.beginPath();
            ctx.moveTo(screenwidth - 25, 240);
            ctx.lineTo(screenwidth - 25, 250);
            ctx.stroke();

            ctx.textAlign = 'center';
            ctx.font = '20px serif';
            ctx.fillText('0m', 25, 265);
            ctx.fillText((Math.round(ovenlength * 10) / 20).toString() + 'm', (screenwidth - 50) / 2 + 25, 265);
            ctx.fillText((Math.round(ovenlength * 10) / 10).toString() + 'm', screenwidth - 25, 265);


            ovendata.forEach(element => {
                //STEIN
                if (element.auskleidungsmaterial == "stein") {
                    //einschicht

                    if (element.auskleidungsmethode == "einschicht") {
                        ctx.fillStyle = NameToColor(element.arbeitsfutterProdukt);

                        let x_level = getXlevel(element.historie[element.historie.length - 1].startposition);
                        let width = getWidth(element.historie[element.historie.length - 1].laenge)

                        ctx.fillRect(x_level, 11, width, 60);
                        ctx.fillRect(x_level, 169, width, 60);

                        ctx.beginPath();
                        ctx.moveTo(x_level, 11);
                        ctx.lineTo(x_level, 71);
                        ctx.lineTo(x_level + width, 71);
                        ctx.lineTo(x_level + width, 11);
                        ctx.stroke();

                        ctx.beginPath();
                        ctx.moveTo(x_level, 229);
                        ctx.lineTo(x_level, 169);
                        ctx.lineTo(x_level + width, 169);
                        ctx.lineTo(x_level + width, 229);
                        ctx.stroke();

                    }
                    //zweischicht
                    else {
                        ctx.fillStyle = NameToColor(element.isolierfutterProdukt);

                        let x_level = getXlevel(element.historie[element.historie.length - 1].startposition);
                        let width = getWidth(element.historie[element.historie.length - 1].laenge)

                        ctx.fillRect(x_level, 11, width, 30);
                        ctx.fillRect(x_level, 199, width, 30);

                        ctx.fillStyle = NameToColor(element.arbeitsfutterProdukt);

                        x_level = getXlevel(element.historie[element.historie.length - 1].startposition);
                        width = getWidth(element.historie[element.historie.length - 1].laenge)

                        ctx.fillRect(x_level, 41, width, 30);
                        ctx.fillRect(x_level, 169, width, 30);

                        ctx.beginPath();
                        ctx.moveTo(x_level, 11);
                        ctx.lineTo(x_level, 71);
                        ctx.lineTo(x_level + width, 71);
                        ctx.lineTo(x_level + width, 11);
                        ctx.stroke();

                        ctx.beginPath();
                        ctx.moveTo(x_level, 41);
                        ctx.lineTo(x_level + width, 41);
                        ctx.stroke();

                        ctx.beginPath();
                        ctx.moveTo(x_level, 199);
                        ctx.lineTo(x_level + width, 199);
                        ctx.stroke();

                        ctx.beginPath();
                        ctx.moveTo(x_level, 229);
                        ctx.lineTo(x_level, 169);
                        ctx.lineTo(x_level + width, 169);
                        ctx.lineTo(x_level + width, 229);
                        ctx.stroke();
                    }
                }
                //MASSE
                else if (element.auskleidungsmaterial == "masse") {
                    ctx.fillStyle = NameToColor(element.arbeitsfutterProdukt);

                    let x_level = getXlevel(element.historie[element.historie.length - 1].startposition);
                    let width = getWidth(element.historie[element.historie.length - 1].laenge)

                    ctx.fillRect(x_level, 11, width, 60);
                    ctx.fillRect(x_level, 169, width, 60);

                    ctx.beginPath();
                    ctx.moveTo(x_level, 11);
                    ctx.lineTo(x_level, 71);
                    ctx.lineTo(x_level + width, 71);
                    ctx.lineTo(x_level + width, 11);
                    ctx.stroke();

                    ctx.beginPath();
                    ctx.moveTo(x_level, 229);
                    ctx.lineTo(x_level, 169);
                    ctx.lineTo(x_level + width, 169);
                    ctx.lineTo(x_level + width, 229);
                    ctx.stroke();
                }
            });
            let legende_index = 1;
            color_info.forEach(element => {
                ctx.fillStyle = element.color;
                ctx.fillRect(24, 260 + 25 * legende_index, 50, 14);
                ctx.fillStyle = "#000000";
                ctx.textAlign = 'left';
                ctx.fillText(element.name, 80, 274 + 25 * legende_index);
                legende_index++;
            });
        }
    }, [ovendata]);


    return (
        <div>
            <canvas id={can_id} />
        </div>
    );
}

