import {create} from 'zustand';
import { APIGetOvens } from '../api/APIGetOvens';
import {APIGetOven} from '../api/APIGetOven';
import {APILoadPositionDetails} from '../api/APILoadPositionDetails';

const useLiningChartsState = create((set,get) => ({
  setState : (newstate ) => set(state => newstate),

  Oefen: [],
  setOefen: (wert) => set(state => ({ Oefen: wert })),
  selectedOfen: null,
  setSelectedOfen: (wert) => set(state => ({ selectedOfen: wert })),

  OfenName: "",
  setOfenName: (wert) => set(state => ({ OfenName: wert })),
  OfenLaenge: "",
  setOfenLaenge: (wert) => set(state => ({ OfenLaenge: wert })),
  OfenKommentar: "",
  setOfenKommentar: (wert) => set(state => ({ OfenKommentar: wert })),

  OfenCommand: "",
  setOfenCommand: (wert) => set(state => ({ OfenCommand: wert })),
  OfenResponse: null,
  setOfenResponse: (wert) => set(state => ({ OfenResponse: wert })),
  OfenDialogue: false,
  setOfenDialogue: (wert) => set(state => ({ OfenDialogue: wert })),
  OfenLoading: false,
  setOfenLoading: (wert) => set(state => ({ OfenLoading: wert })),
  OfenNoConnection: false,
  setOfenNoConnection: (wert) => set(state => ({ OfenNoConnection: wert })),
  NewOvenCreation: false,
  setNewOvenCreation: (wert) => set(state => ({ NewOvenCreation: wert })),

  selectedPosition: null,
  setSelectedPosition: (wert) => set(state => ({ selectedPosition: wert })),

  positionMode: "none",
  setPositionMode: (wert) => set(state => ({ positionMode: wert })),


  PositionLoading: false,
  setPositionLoading: (wert) => set(state => ({ PositionLoading: wert })),
  PositionInfo: null,
  setPositionInfo: (wert) => set(state => ({ PositionInfo: wert })),

  OfenStateOne: null,
  setOfenStateOne: (wert) => set(state => ({ OfenStateOne: wert })),
  OfenStateTwo: null,
  setOfenStateTwo: (wert) => set(state => ({ OfenStateTwo: wert })),

  PositionCommand: "",
  setPositionCommand: (wert) => set(state => ({ PositionCommand: wert })),
  PositionResponse: null,
  setPositionResponse: (wert) => set(state => ({ PositionResponse: wert })),
  PositionDialogue: false,
  setPositionDialogue: (wert) => set(state => ({ PositionDialogue: wert })),
  loadOven: async (ovenId) => {
  let result = await APIGetOven(ovenId);  
  
  set(state => 
    {
    let temp = state.Oefen.filter(f=> f.id !== ovenId);
    temp.push(result);

    return {Oefen:temp}});
},
AllOvenPositions: [],
loadOvenPositions: async (ovenId) => {
  let result = await APILoadPositionDetails(ovenId);
  
  set(state => 
    {      
    let temp = state.AllOvenPositions.filter(f=> f.ofenID !== ovenId);
    
    return {AllOvenPositions:[...temp,...result]}});
},
loadOvens: async () => {
  let result = await APIGetOvens();  
  
  set(state => 
    
    {return {Oefen:result}});
},
DisplayedPosition: null,
setDisplayedPosition: (pos) => set(state => ({ DisplayedPosition: pos })),
}));

export default useLiningChartsState;