import Auth from './Auth.ts';

export async function APICreatePositionsHistorie(inc_PositionID,inc_Startposition,inc_Laenge,inc_Staerke) {

    return Auth().then((headers) => {
        const params = {
          PositionID:inc_PositionID,
          Startposition: inc_Startposition,
          Laenge: inc_Laenge,
          Staerke: inc_Staerke
        };
        const url = window.config.BACKENDURL + "/api/v1/PositionsHistorie";
        return fetch(url, {
          method: "POST",
          //@ts-ignore
          headers: headers,
          body: JSON.stringify(params)
        }).then((result) => {
          return result;
        });
      });
}