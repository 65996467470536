import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";
import './Positions.css'
import useLiningChartsState from '../state/liningcharts';
import { APICreatePosition } from '../api/APICreatePosition';
import { APIDeletePosition } from '../api/APIDeletePosition';
import { APICreatePositionsHistorie } from '../api/APICreatePositionsHistorie';
import { APILoadPositionDetails } from '../api/APILoadPositionDetails';
import { useNavigate } from 'react-router';
import { useEffect, useState } from "react";
import LiningChart from '../components/lining_chart';
import Positioninfo from "../components/positioninfo";

function Positions() {
  const history = useNavigate();

  const selectedPosition = useLiningChartsState(state => state.selectedPosition);
  const setSelectedPosition = useLiningChartsState(state => state.setSelectedPosition)

  const Oefen = useLiningChartsState(state => state.Oefen);

  const PositionLoading = useLiningChartsState(state => state.PositionLoading);
  const setPositionLoading = useLiningChartsState(state => state.setPositionLoading)
  const PositionInfo = useLiningChartsState(state => state.PositionInfo);
  const setPositionInfo = useLiningChartsState(state => state.setPositionInfo);

  const positionMode = useLiningChartsState(state => state.positionMode);
  const setPositionMode = useLiningChartsState(state => state.setPositionMode)

  const PositionCommand = useLiningChartsState(state => state.PositionCommand);
  const setPositionCommand = useLiningChartsState(state => state.setPositionCommand)
  const PositionResponse = useLiningChartsState(state => state.PositionResponse);
  const setPositionResponse = useLiningChartsState(state => state.setPositionResponse)
  const PositionDialogue = useLiningChartsState(state => state.PositionDialogue);
  const setPositionDialogue = useLiningChartsState(state => state.setPositionDialogue)
  const loadOven = useLiningChartsState(state => state.loadOven);
  const loadOvenPositions = useLiningChartsState(state => state.loadOvenPositions);
  const AllOvenPositions = useLiningChartsState(state => state.AllOvenPositions);
  const DisplayedPosition = useLiningChartsState(state => state.DisplayedPosition);
  const setDisplayedPosition = useLiningChartsState(state => state.setDisplayedPosition);

  let { ovenId } = useParams();


  useEffect(() => {
    // Ofen laden;
    loadOven(ovenId);
    loadOvenPositions(ovenId);
  }, [ovenId]);



  const Ofen = Oefen.find(o => o.id === ovenId);
  const OfenPositionen = AllOvenPositions.filter(o => o.ofenID === ovenId);

  useEffect(() => {
    // Ofen laden;
    if (selectedPosition || selectedPosition == 0) {
      const selectedPositionobj = OfenPositionen[selectedPosition];
      setDisplayedPosition((selectedPositionobj) ? JSON.parse(JSON.stringify(selectedPositionobj)) : null);
    }
  }, [selectedPosition]);

  useEffect(() => {
    setSelectedPosition(null);
    setPositionMode("none");
    ClearFields();
  }, []);


  useEffect(() => {
    if (PositionResponse == null) {
      return;
    }
    if (PositionResponse == false) {
      setPositionCommand("");
      setPositionResponse(null);
      return;
    }
    switch (PositionCommand) {
      case "del":
        setPositionLoading(true);

        APIDeletePosition(OfenPositionen[selectedPosition].id).then(() => {
          AllOvenPositions.splice(AllOvenPositions.indexOf(AllOvenPositions.filter(o => o.id === OfenPositionen[selectedPosition].id)), 1);
          setSelectedPosition(null);
          ClearFields();
          setPositionLoading(false);
          setPositionCommand("");
          setPositionResponse(null);
          setPositionMode("none");
        });
        break;
      case "update":
        setPositionLoading(true);

        let start_posi;
        let lng;
        let strk;

        if (!isNaN(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].startposition)) {
          start_posi = parseFloat(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].startposition);
        }
        else {
          console.log("PositionsHistorieStartposition not a float");
          return;
        }
        if (!isNaN(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].laenge)) {
          lng = parseFloat(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].laenge);
        }
        else {
          console.log("PositionsHistorieLaenge not a float");
          return;
        }
        if (!isNaN(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].staerke)) {
          strk = parseFloat(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].staerke);
        }
        else {
          console.log("PositionsHistorieStaerke not a float");
          return;
        }
        setPositionLoading(true);
        APICreatePositionsHistorie(OfenPositionen[selectedPosition].id, start_posi, lng, strk).then((res) => {
          OfenPositionen[selectedPosition].historie[OfenPositionen[selectedPosition].historie.length - 1].startposition = start_posi;
          OfenPositionen[selectedPosition].historie[OfenPositionen[selectedPosition].historie.length - 1].laenge = lng;
          OfenPositionen[selectedPosition].historie[OfenPositionen[selectedPosition].historie.length - 1].staerke = strk;

          ClearFields();
          setSelectedPosition(null);
          PositionSelected(selectedPosition);

          setPositionLoading(false);
          setPositionCommand("");
          setPositionResponse(null);
          setPositionMode("none");
        });

        break;
      case "create":
        setPositionLoading(true);
        let posi_durchmesser;
        let iso_dicke;
        let formatoneratio;
        let formattworatio;



        let n_start_posi;
        let n_lng;
        let n_strk;

        if (!isNaN(DisplayedPosition.durchmesser)) {
          posi_durchmesser = parseFloat(DisplayedPosition.durchmesser);
        }
        else {
          console.log("Durchmesser not a float");
          return;
        }

        if (!isNaN(DisplayedPosition.isolierfutterDicke)) {
          iso_dicke = parseFloat(DisplayedPosition.isolierfutterDicke);
        }
        else {
          console.log("IsolierfutterDicke not a float");
          return;
        }

        if (!isNaN(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].startposition)) {
          n_start_posi = parseFloat(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].startposition);
        }
        else {
          console.log("HistorieStartposition not a float");
          return;
        }
        if (!isNaN(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].laenge)) {
          n_lng = parseFloat(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].laenge);
        }
        else {
          console.log("HistorieLaenge not a float");
          return;
        }
        if (!isNaN(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].staerke)) {
          n_strk = parseFloat(DisplayedPosition.historie[DisplayedPosition.historie.length - 1].staerke);
        }
        else {
          console.log("HistorieStaerke not a float");
          return;
        }

        if (!isNaN(DisplayedPosition.formatEinsRatio)) {
          formatoneratio = Math.round(parseFloat(DisplayedPosition.formatEinsRatio));
        }
        else {
          console.log("FormatEinsRatio not a float");
          return;
        }
        if (!isNaN(DisplayedPosition.formatZweiRatio)) {
          formattworatio = Math.round(parseFloat(DisplayedPosition.formatZweiRatio));
        }
        else {
          console.log("FormatZweiRatio not a float");
          return;
        }

        APICreatePosition(ovenId, DisplayedPosition.name, DisplayedPosition.kommentar, posi_durchmesser, DisplayedPosition.ofenmantelVerschmutzung, DisplayedPosition.ofenmantelVerformung, DisplayedPosition.auskleidungsmaterial, DisplayedPosition.auskleidungsmethode, DisplayedPosition.arbeitsfutterHersteller, DisplayedPosition.arbeitsfutterProdukt,
          DisplayedPosition.isolierfutterHersteller, DisplayedPosition.isolierfutterProdukt, iso_dicke, DisplayedPosition.moertelArbeitsfutter, DisplayedPosition.moertelIsolierfutter, DisplayedPosition.ankerLieferant, DisplayedPosition.ankerTyp, DisplayedPosition.ankerQualitaet, DisplayedPosition.formatTyp, DisplayedPosition.formatEins, DisplayedPosition.formatZwei, DisplayedPosition.passSteinEins, DisplayedPosition.passSteinZwei,
          formatoneratio, formattworatio, DisplayedPosition.ringeVerband, DisplayedPosition.knirschMoertel, DisplayedPosition.haltering, DisplayedPosition.konus, DisplayedPosition.lifterzone, DisplayedPosition.kettenzone, DisplayedPosition.kammfutter, DisplayedPosition.opferbleche, DisplayedPosition.dammring, DisplayedPosition.spiralmauerwerk, n_start_posi, n_lng, n_strk).then((response) => {

            if (response.status == "OK") {
              DisplayedPosition.id = response.id;
              AllOvenPositions.push(DisplayedPosition);
            }
            else {
              console.log("ERROR");
            }
            ClearFields();
            setSelectedPosition(null);
            setPositionLoading(false);
            setPositionCommand("");
            setPositionResponse(null);
            setPositionMode("none");
          });

        break;
      default:
        console.log("default");
    }

  }, [PositionResponse]);


  function DialogueYes() {
    setPositionDialogue(false);
    setPositionResponse(true);
  }
  function DialogueNo() {
    setPositionDialogue(false);
    setPositionResponse(false);
  }

  function PositionSelected(posi) {

    setSelectedPosition(posi);
    setPositionMode("existing");
  }

  function PositionNew() {
    setSelectedPosition(null);
    ClearFields();
    let neuer_eintrag = {
      ankerLieferant: "", ankerQualitaet: "", ankerTyp: "", arbeitsfutterHersteller: "", arbeitsfutterProdukt: "", auskleidungsmaterial: "stein",
      auskleidungsmethode: "einschicht", dammring: false, durchmesser: 0, formatEins: "", formatEinsRatio: 0, formatTyp: "vdz", formatZwei: "", formatZweiRatio: 0,
      haltering: false, id: 0, isolierfutterDicke: 0, isolierfutterHersteller: "", isolierfutterProdukt: "", kammfutter: false, kettenzone: false, knirschMoertel: "knirsch",
      kommentar: "", konus: false, lifterzone: false, moertelArbeitsfutter: "", moertelIsolierfutter: "", name: "", ofenID: ovenId, ofenmantelVerformung: "none",
      ofenmantelVerschmutzung: "none", opferbleche: false, passSteinEins: "", passSteinZwei: "", ringeVerband: "ringe", spiralmauerwerk: false, historie: [{ laenge: 0, startposition: 0, staerke: 0 }]
    }
    setDisplayedPosition(neuer_eintrag);
    setPositionMode("new");
  }

  function Save() {
    if (positionMode == "existing") {
      setPositionCommand("update");
      setPositionDialogue(true);
    }
    if (positionMode == "new") {
      setPositionCommand("create");
      setPositionDialogue(true);
    }
  }

  function Delete() {
    setPositionCommand("del");
    setPositionDialogue(true);
  }

  function BackToOvens() {
    history("/")
  }




  function ClearFields() {
    setDisplayedPosition(null);
  }


  const LoadingElement = (PositionLoading == true || Ofen === undefined) ?
    <div className='dialogue'>
      <p>Loading...</p>
    </div>
    : [];
  const DialogueElement = (PositionDialogue == true) ?
    <div style={{ 'z-index': 45 }}>
      <p>Sind sie sich sicher?</p>
      <table>
        <tbody>
          <tr>
            <td>
              <button onClick={DialogueYes}>Ja</button>
            </td>
            <td>
              <button onClick={DialogueNo}>Nein</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    : [];


  const PositionenDisplay = (OfenPositionen) ? OfenPositionen.map((posi, i) => {
    return (i == selectedPosition) ?
      <tr style={{ background: '#F15507' }} key={i} onClick={() => PositionSelected(i)}><td>{posi.name}</td><td>{posi.historie[posi.historie.length - 1].startposition}</td><td>{posi.historie[posi.historie.length - 1].laenge}</td></tr>
      :
      <tr key={i} onClick={() => PositionSelected(i)}><td>{posi.name}</td><td>{posi.historie[posi.historie.length - 1].startposition}</td><td>{posi.historie[posi.historie.length - 1].laenge}</td></tr>
      ;
  }) : [];






  return (

    <div className="wrapper">
      <div className='main'>
        <h2>
          Refra Lining Charts
          {LoadingElement}
          {DialogueElement}
        </h2>
      </div>
      <div className="twoButtons">
        <button onClick={BackToOvens}>Zurück Zu Öfen</button>
        <button disabled={positionMode != "existing"} onClick={Delete}>Position Löschen</button>
      </div>

      <div className="main">
        <style>
        </style>

        <LiningChart ovendata={OfenPositionen} ovenlength={Ofen != undefined ? Ofen.laenge : 1} screenwidth={window.innerWidth * 0.8} />
        <div className='tablebox'>
          <table cellspacing="0" cellpadding="4">
            <tbody>
              <th>Positionsname</th><th>Startposition (m)</th><th>Länge (m)</th>
              {PositionenDisplay}
            </tbody>
          </table>
        </div>
        <div className="nachLinks">
          <button disabled={positionMode == "new"} onClick={PositionNew}>Neue Position</button>

          <Positioninfo />

          <button disabled={positionMode == "none"} hidden={positionMode == "none"} onClick={Save}>Speichern</button>
        </div>
      </div>
    </div>
  );
}

export default Positions;