import Auth from './Auth.ts';

export async function APIChangeOven(ID, Name, Kommentar, Laenge) {

    return Auth().then((headers) => {
        const params = {
            ID: ID,
            Name: Name,
            Kommentar: Kommentar,
            Laenge: Laenge
        };
        const url = window.config.BACKENDURL + "/api/v1/Oven";
        return fetch(url, {
          method: "POST",
          //@ts-ignore
          headers: headers,
          body: JSON.stringify(params)
        }).then((result) => {
          return result.json();
        });
      });
}